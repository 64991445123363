<template>
  <div class="helper" :class="target.position" :style="style">
    <div class="helper__close" @click="$emit('close')">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.25 5.25L18.75 18.75"
          stroke="#9A3374"
          stroke-width="1.5"
          stroke-linecap="square"
        />
        <path
          d="M18.75 5.25L5.25 18.75"
          stroke="#9A3374"
          stroke-width="1.5"
          stroke-linecap="square"
        />
      </svg>
    </div>
    <div class="helper__row">
      <div class="helper__icon mr-4">
        <img :src="target.icon" />
      </div>
      <div class="helper__text">
        <div class="helper__title" v-if="target.title">{{ target.title }}</div>
        <div class="helper__description">
          {{ target.description }}
        </div>
      </div>
    </div>

    <div class="helper__buttons">
      <div class="helper__counter">{{ index + 1 }}/{{ length }}</div>
      <div
        class="helper__button button button_pink"
        @click="nextStep"
      >
        {{ index + 1 !== length ? "Вперед" : "Вперед" }}
        <svg
          class="ml-2"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9 4.5L16.5 12L9 19.5"
            stroke="white"
            stroke-width="1.5"
            stroke-linecap="square"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Helper",
  props: {
    target: Object,
    position: Object,
    index: Number,
    length: Number,
  },
  computed: {
    style() {
      let style = {};
      if (
        Object.keys(this.position).length &&
        !this.$root.isMobile &&
        !this.$root.isTablet
      ) {
        if (this.target.position === "bottom") {
          style.top = `${this.position.top + this.position.childHeight + 25}px`;
          style.left = `${this.position.left}px`;
        } else if (this.target.position === "right") {
          style.top = `${this.position.top + 30}px`; //30 или this.position.childHeight / 2
          style.left = `${
            this.position.left +
            this.position.childWidth +
            (this.position.childWidth / 100) * 10 +
            30
          }px`;
        } else if (this.target.position === "left") {
          style.top = `${this.position.top + 30}px`; //30 или this.position.childHeight / 2
          style.right = `${
            this.position.right * -1 +
            this.position.childWidth +
            (this.position.childWidth / 100) * 10 +
            30
          }px`;
        }  if (this.target.position === "bottom-right") {
          style.top = `${this.position.top + this.position.childHeight + 25}px`;
          style.right = `${this.position.right * -1}px`;
        } 
      }
      return Object.entries(style)
        .map(([k, v]) => `${k}:${v}`)
        .join(";");
    },
  },
  methods: {
    nextStep() {
      this.$emit("nextStep");
    },
  },
  mounted() {
    if(document.querySelector('.fixed-header .header')) {
       document.querySelector('.fixed-header .header').style.position = 'relative'
    }
    this.$emit("setPosition", this.target);
   
  },
};
</script>

<style lang="scss" scoped>
.helper {
  pointer-events: all;
  position: absolute;
  z-index: 9999999999999;
  width: 450px;
  height: auto;
  padding: 16px 20px;
  background: #fff;
  border-top: 8px solid #830051;
  box-shadow: 0px 4px 5px 0px #00000040;

  &__close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    z-index: 100;
  }

  &::before {
    position: absolute;
    width: 0;
    height: 0;
    display: block;
    content: "";
  }
  @media screen and (min-width: 1221px) {
    &.bottom {
      // transform: translateX(-50%);
      // &::before {
      //   bottom: 100%;
      //   left: 50%;
      //   transform: translateX(-50%);
      //   border-left: 5px solid transparent;
      //   border-right: 5px solid transparent;
      //   border-bottom: 5px solid #fceecc;
      // }
    }

    &.bottom-right {
      // transform: translateX(-100%);
    }

    &.right {
      // transform: translateY(-50%);
      &::before {
        top: 50%;
        right: 100%;
        transform: translateY(-50%);
        border-top: 5px solid transparent;
        border-left: 5px solid transparent;
        border-right: 5px solid #fceecc;
        border-bottom: 5px solid transparent;
      }
    }

    &.left {
      // transform: translateY(-50%);
      &::before {
        top: 50%;
        left: 100%;
        transform: translateY(-50%);
        border-top: 5px solid transparent;
        border-left: 5px solid #fceecc;
        border-right: 5px solid transparent;
        border-bottom: 5px solid transparent;
      }
    }
  }

  @media screen and (max-width: 1220px) {
    transform: none;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 0;
    &::before {
      display: none;
    }

    &.topPosition {
      bottom: auto;
      top: 0;
    }
  }

  &__title {
    margin-bottom: 16px;
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #1f1f1f;
  }

  &__description {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
  }

  &__text {
    padding-right: 48px;
  }

  &__row {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    
  }

  &__buttons {
    margin-top: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
  }

  &__counter {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #B2B4B4;
  }

  &__button {
    margin-left: auto;
  }
}
</style>