<template>
  <div class="cabinet-wrapper">
    <Header />
    <MaterialsFilter class="d-xl-none" />
    <div class="content">
      <div class="cabinet__row">
        <div class="cabinet__aside pt-12">
          <CabinetAside />
          <MaterialsFilter
            v-if="
              showMaterialsFilter && (!$root.isMobile && !$root.isTablet)
            "
          />
        </div>
        <router-view :showDiseases="showHelper"/>
      </div>
    </div>
    <HelperStart
      @closeHelp="closeHelp"
      @startHelp="startHelp"
      v-if="helperStart"
      :text="helperText"
    />
    <Helper
      v-if="showHelper && helperTargets[currentTarget]"
      @setPosition="setPosition"
      @nextStep="nextStep"
      :target="helperTargets[currentTarget]"
      :position="helperPosition"
      :index="currentTarget"
      :length="helperTargets.length"
      @close="closeOnboarding"
    />
    <Footer />
  </div>
</template>

<script>
import Header from "../components/main/Header.vue";
import Footer from "../components/Footer.vue";
import CabinetAside from "@/components/pageComponents/cabinet/CabinetAside.vue";
import MaterialsFilter from "@/components/pageComponents/cabinet/MaterialsFilter.vue";
import { mapActions, mapGetters } from "vuex";
import Helper from "@/components/main/Helper.vue";
import HelperStart from "@/components/main/HelperStart.vue";
import { bus } from "@/main";
export default {
  components: {
    Header,
    Footer,
    CabinetAside,
    MaterialsFilter,
    Helper,
    HelperStart,
  },
  name: "cabinet",
  data: () => ({
    showHelper: false,
    helperStart: false,
    helperText: {
      title: "Настройка «Моего AZ–MOST»",
      description:
        "Настройте портал по интересующим терапевтическим областям и заболеваниям",
    },
    currentTarget: 0,
    helperPosition: {},
    helperTargets: [
      {
        title: "Выберите терапевтические области",
        icon: require("@/assets/img/onboarding/my-az-most/icon1.svg"),
        key: "helperNos",
        position: "bottom",
      },
      {
        title: "Выберите заболевания",
        icon: require("@/assets/img/onboarding/my-az-most/icon2.svg"),
        description: "По которым хотите получать материалы",
        key: "helperDis",
        position: "bottom",
      },
      {
        title: "Ваша лента настроена",
        icon: require("@/assets/img/onboarding/my-az-most/icon3.svg"),
        description: "Сохраните выбранные параметры",
        key: "helperSave",
        position: "right",
      },
    ],
  }),
  computed: {
    ...mapGetters(["user", "pageVerify", "Nosologys"]),
    showMaterialsFilter() {
      const routes = [
        'MaterialsDetail',
        'Materials',
        'RecommendedEvents',
        'RecommendedEventsDetail',
      ]
      return routes.includes(this.$route.name)
    }
  },
  methods: {
    ...mapActions([
      "getSession",
      "fetchPageVerify",
      "getSession",
      "fetchNosologys",
      "logout",
      "checkTutorial",
    ]),
    openHelper() {
        this.helperStart = true;
    },

    startHelp() {
      this.startOnboarding();
      this.helperStart = false;
    },
    closeHelp(check) {
      if (check) {
        this.checkTutorial({ id: this.user.id, flags: ["myazmost"] });
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "onboarding start", {
            "onboarding start": {
              "banner interaction": "skip",
              ...this.$root.ymFields,
            },
          });
        }
      }
      bus.$emit("scrollLock", false);
      this.helperStart = false;
    },

    startOnboarding() {
      let routeArray = this.$route.path.split('/')
      this.showHelper = true;
      if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "onboarding start", {
            "onboarding start": {
              "banner interaction": "start",
              ...this.$root.ymFields,
            },
          });
          ym(91468266, "reachGoal", "onboarding", {
            onboarding: {
              [routeArray[routeArray.length-1]]: 1,
              ...this.$root.ymFields,
            },
          });
        }
    },
    closeOnboarding() {
       this.showHelper = false;
      document.querySelector("body").style.pointerEvents = "";
      document.querySelectorAll(".highlightedElement").forEach((item) => {
        item.classList.remove("highlightedElement");
      });
      window.scrollTo({ top: 0, behavior: "smooth" });
      bus.$emit("scrollLock", false);
      this.checkTutorial({ id: this.user.id, flags: ["myazmost"] });
    },

    setPosition(target) {
      // if (this.$root.isMobile || this.$root.isTablet) {
      //   if (this.currentTarget === 0) {
      //     bus.$emit("openMenu");
      //   }
      //   if (this.currentTarget === 6) {
      //     bus.$emit("closeMenu");
      //   }
      // }
      document.querySelector("body").style.pointerEvents = "none";
      bus.$emit("scrollLock", true);
      let relativePos = {};
      if (!target.key && document.querySelector(`[data-id="${target.key}"]`))
        return;
      let child = document.querySelector(`[data-id="${target.key}"]`);
      let parentPos = document.body.getBoundingClientRect();
      let childPos = child.getBoundingClientRect();
      if (!target.key.includes("header")) {
        const yOffset = -100;
        const y =
          child.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
      document.querySelectorAll(".highlightedElement").forEach((item) => {
        item.classList.remove("highlightedElement");
      });
      child.classList.add("highlightedElement");
      relativePos.top = childPos.top + window.pageYOffset;
      relativePos.right = childPos.right - parentPos.right;
      relativePos.bottom = childPos.bottom - parentPos.bottom;
      relativePos.left = childPos.left;
      relativePos.childWidth = childPos.width;
      relativePos.childHeight = childPos.height;
      this.helperPosition = relativePos;
    },
    nextStep() {
      let routeArray = this.$route.path.split('/')
      if (this.currentTarget < this.helperTargets.length - 1) {
         this.currentTarget++;
         if (typeof ym !== "undefined") {
         
          ym(91468266, "reachGoal", "onboarding", {
            onboarding: {
              [routeArray[routeArray.length-1]]: this.currentTarget+1,
              ...this.$root.ymFields,
            },
          });
        }
       
        this.setPosition(this.helperTargets[this.currentTarget]);
        
      } else {
        this.showHelper = false;
        document.querySelector("body").style.pointerEvents = "";
        document.querySelectorAll(".highlightedElement").forEach((item) => {
          item.classList.remove("highlightedElement");
        });
        window.scrollTo({ top: 0, behavior: "smooth" });
        bus.$emit("scrollLock", false);
        this.checkTutorial({ id: this.user.id, flags: ["myazmost"] });
        if (typeof ym !== "undefined") {
          ym(91468266, "reachGoal", "onboarding finish - setting up", {
            onboarding: {
              finish: "setting up",
              ...this.$root.ymFields,
            },
          });
        }
      }
    },
  },
  beforeDestroy() {
    bus.$off("startCabinetHelp", this.openHelper);
  },
  created() {
     bus.$on("startCabinetHelp", this.openHelper);
  },
  async mounted() {
    bus.$emit("scrollLock", false);
    await this.fetchPageVerify(this.$route.path);
    if (!Object.keys(this.user).length) {
      await this.getSession();
    }
    if (!Object.keys(this.Nosologys).length) {
      await this.fetchNosologys();
    }
  },
  watch: {
    async $route(to, from) {
      if (to.path !== from.path) {
        await this.fetchPageVerify(this.$route.path);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cabinet-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  min-height: 100vh;
  height: 100%;
  position: relative;

  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 50vw;
    height: 100vh;
    background-color: #fff;
    content: "";
    display: block;
    @media screen and (max-width: 1220px) {
      display: none;
    }
  }

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    width: 50vw;
    height: 100%;
    background-color: #f8f8f8;
    content: "";
    display: block;
    @media screen and (max-width: 1220px) {
      display: none;
    }
  }
}

.cabinet__row {
  display: grid;
  grid-template-columns: 280px 1fr;
  width: 100%;
  @media screen and (max-width: 1220px) {
    display: block;
  }
  @media screen and (max-width: 767px) {
  }
}

.footer {
  z-index: 100;
}

.header {
  z-index: 100001;
}

.content {
  z-index: 1000;
  min-height: 100%;
  flex-grow: 1;
  display: flex;
  align-items: stretch;
  @media screen and (max-width: 1220px) {
    background-color: #f8f8f8;
    // z-index: 99999999999;
  }
}

.header {
  background-color: #fff;
}

.cabinet__aside {
  padding-right: 24px;
  position: sticky;
  top: 15px;
  overflow-y: auto;
  max-height: 100vh;
  // -ms-overflow-style: scrollbar;
  // scrollbar-color: #d8dada transparent;
  // scrollbar-width: thin;
  scroll-margin-right: 3px;
  scroll-padding-right: 3px;

  &::-webkit-scrollbar {
    width: 5px;
    height: 8px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #d8dada;
    border-radius: 5px;
    height: 8px;
  }

  &::-webkit-scrollbar--track-piece {
    border-radius: 5px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    border-radius: 5px;
    background-color: #d8dada;
    border: 3px solid #fff;
  }

  &::-moz-scrollbar-button:decrement,
  &::-moz-scrollbar-button:increment,
  &::-moz-scrollbar-button:vertical:start:decrement,
  &::-moz-scrollbar-button:vertical:end:increment,
  &::-moz-scrollbar-button:horizontal:start:decrement,
  &::-moz-scrollbar-button:horizontal:end:increment,
  &::-webkit-scrollbar-button:vertical:start:decrement,
  &::-webkit-scrollbar-button:vertical:end:increment,
  &::-webkit-scrollbar-button:horizontal:start:decrement,
  &::-webkit-scrollbar-button:horizontal:end:increment {
    width: 0px !important;
    height: 0px !important;
  }
  @media screen and (max-width: 1220px) {
    display: none;
  }
  @media screen and (max-width: 767px) {
    display: none;
  }
}
</style>

<style>
.cabinet__row .material__title {
  -webkit-line-clamp: 4;
  overflow: hidden;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

/* .cabinet-wrapper .material__title {
  font-size: 18px !important;
  line-height: 24px !important;
} */

.cabinet-wrapper .material__image-container {
  height: 150px !important;
}

.cabinet-wrapper .material__description {
  display: none !important;
}

.cabinet-wrapper .material__category {
  font-size: 12px !important;
}
</style>